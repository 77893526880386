import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faGift,
  faHandHoldingDollar,
  faMedal,
  faSquarePollHorizontal
} from "@fortawesome/free-solid-svg-icons";

export const services = [
  {
    id: 1,
    bgColor: "bg-accent",
    titleColor: "text-white",
    descColor: "text-white",
    servIcon: <FontAwesomeIcon icon={faHandHoldingDollar} className="h-[4.25rem] w-[4.25rem] fill-white" style={{ fontSize: '4.25rem', color: 'white' }}></FontAwesomeIcon>,
    servTitle: "LCOT Tipping & Rewards",
    servDesc: "Reward streamers with LCOT tokens and engage viewers with interactive tipping milestones.",
  },
  {
    id: 2,
    bgColor: "bg-[#f0e1d4]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: <FontAwesomeIcon icon={faGift} className="h-[4.25rem] w-[4.25rem] fill-white" style={{ fontSize: '4.25rem', color: '#284be5' }}></FontAwesomeIcon>,
    servTitle: "Viewer Raffles & Prizes",
    servDesc: "Create excitement with LCOT-powered raffles, exclusive content, and viewer challenges.",
  },
  {
    id: 3,
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: <FontAwesomeIcon icon={faMedal} className="h-[4.25rem] w-[4.25rem] fill-white" style={{ fontSize: '4.25rem', color: '#284be5' }}></FontAwesomeIcon>,
    servTitle: "Stream Challenges & Contests",
    servDesc: "Boost participation with LCOT-funded challenges and team-based competitions.",
  },
  {
    id: 4,
    bgColor: "bg-gray",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: <FontAwesomeIcon icon={faSquarePollHorizontal} className="h-[4.25rem] w-[4.25rem] fill-white" style={{ fontSize: '4.25rem', color: '#284be5' }}></FontAwesomeIcon>,
    servTitle: "Voting & Polling Power",
    servDesc: "Let viewers influence streams by voting with LCOT for content, challenges, and decisions.",
  }
];
