import PatternImg from "../../assets/images/patternImg.jpg";
import PatternImg2 from "../../assets/images/patternImg2.jpg";
import AboutUsBg from "../../assets/images/secBg.jpg";
import { SectionTitle } from "../SectionTitles";
import AboutImg from "../../assets/images/resources/aboutImg.jpg";
import { ReactComponent as Signature } from "../../lib/icons/Signature.svg";
import { GoPrimitiveDot } from "react-icons/go";

const AboutUs = (props) => {
  return (
    <section className="aboutUsWrap py-[4.5rem] md:py-[5.5rem] lg:py-[6.5rem] xl:py-[7.5rem] relative w-full">
      <div
        className="fixedBg bg-left-top bg-no-repeat opacity-20"
        style={{ backgroundImage: `url(${PatternImg})` }}
      ></div>
      <div className="container sm:container md:container lg:container xl:container 2xl:container mx-auto">
        <SectionTitle
          title="Our"
          titleInner="Mission"
          desc="At LCOT (Live Community Operations Token), our mission is to revolutionize how communities connect, collaborate, and thrive. We are dedicated to empowering individuals and creators through a transparent, secure, and decentralized platform that prioritizes unity, trust, and long-term growth."
        ></SectionTitle>
      </div>
      {/* About Us Wrap */}
    </section>
  );
};

export default AboutUs;
