export const bottomMenu = [
  {
    bottomMenuCap: "Terms & Condition",
    bottomMenuLink: "/terms",
  },
  {
    bottomMenuCap: "Privacy Policy",
    bottomMenuLink: "/privacypolicy",
  },
];
