import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const PrivacyPolicyDetail = () => {
  const effectiveDate = "December 1, 2024"; // Variable for the effective date
  const contactEmail = "support@lcot.live";
  return (
      <React.Fragment>
        <Helmet>
          <title>LCOT Live | Privacy Policy</title>
        </Helmet>
        <Header2></Header2>
        <PageTitle
            title="Privacy Policy"
            hometitle="Home"
            homeURL="/"
            currentPage="Privacy Policy"
        ></PageTitle>
        <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
          <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
            <div className="servDetail relative w-full">
              <div className="servDetailDesc flex flex-col gap-6 w-full">
                <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                  Privacy Policy
                </h3>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  **Effective Date:** {effectiveDate}
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  1. Information We Collect
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  - **Personal Information**: When you contact us or interact with
                  certain features, we may collect personal details like your name
                  and email address. <br />
                  - **Non-Personal Information**: We may collect non-identifiable
                  information such as browser type, operating system, and IP address
                  for analytics purposes.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  2. How We Use Your Information
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  - To improve website functionality and user experience. <br />
                  - To communicate updates about our roadmap, services, or policies.
                  <br />
                  - To respond to inquiries and support requests.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  3. Cookies
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  We may use cookies to enhance your browsing experience. By using
                  our website, you consent to the use of cookies. You can disable
                  cookies through your browser settings, but certain features of
                  the site may not function properly.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  4. Sharing of Information
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  - **Third Parties**: We do not sell or rent your personal
                  information to third parties. <br />
                  - **Legal Requirements**: We may disclose information if required
                  by law or to protect the security and integrity of our website.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  5. Data Security
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  We implement robust measures to protect your data from
                  unauthorized access, alteration, or destruction. However, no
                  method of transmission over the internet is entirely secure, and
                  we cannot guarantee absolute security.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  6. Third-Party Links
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  LCOT Live may link to external websites. We are not responsible
                  for their privacy practices or content.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  7. Changes to Privacy Policy
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  We reserve the right to update this Privacy Policy at any time.
                  Any changes will be posted on this page with a new effective
                  date.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  8. Contact
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  For questions or concerns about this Privacy Policy, please
                  contact us at {contactEmail}.
                </p>
              </div>
              {/* Service Detail */}
            </div>
          </div>
          {/* Page Wrap */}
        </section>
        <BottomBar2></BottomBar2>
      </React.Fragment>
  );
};

export default PrivacyPolicyDetail;
