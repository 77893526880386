import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const TermsAndConditionsDetail = () => {
  const effectiveDate = "December 1, 2024"; // Variable for the effective date
  const contactEmail = "support@lcot.live";
  return (
      <React.Fragment>
        <Helmet>
          <title>LCOT Live | Terms and Conditions</title>
        </Helmet>
        <Header2></Header2>
        <PageTitle
            title="Terms and Conditions"
            hometitle="Home"
            homeURL="/"
            currentPage="Terms and Conditions"
        ></PageTitle>
        <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
          <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
            <div className="servDetail relative w-full">
              <div className="servDetailDesc flex flex-col gap-6 w-full">
                <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                  Terms and Conditions
                </h3>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  **Effective Date:** {effectiveDate}
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  1. Acceptance of Terms
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  By accessing or using LCOT Live, you acknowledge that you have
                  read, understood, and agreed to these Terms and Conditions.
                  These terms apply to all users of the website.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  2. Use of the Website
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  - You agree to use the website for lawful purposes only.
                  <br />
                  - You shall not attempt to disrupt the functionality of the
                  website or engage in activities that harm its security or other
                  users.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  3. Content and Intellectual Property
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  All content on LCOT Live, including but not limited to text,
                  images, logos, and videos, is the intellectual property of LCOT
                  Live unless otherwise stated. You may not reproduce, distribute,
                  or modify any content without prior written consent.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  4. Future Services Disclaimer
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  The services and features mentioned on the LCOT Live website,
                  including but not limited to tipping systems, raffles, and
                  voting features, are part of our planned roadmap. These
                  services are subject to change and may not be immediately
                  available.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  5. Third-Party Links
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  Our website may include links to external websites. LCOT Live
                  is not responsible for the content, privacy policies, or
                  practices of any third-party sites.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  6. Limitation of Liability
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  LCOT Live will not be held liable for any direct, indirect, or
                  consequential damages resulting from the use or inability to
                  use our website.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  7. Changes to Terms
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  LCOT Live reserves the right to modify these terms at any time.
                  Changes will be effective upon posting. Continued use of the
                  website constitutes acceptance of the updated terms.
                </p>
                <h4 className="text-accent2 font-Poppins font-semibold text-[1.25rem] md:text-[1.5rem]">
                  8. Contact
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  For any questions about these Terms and Conditions, please
                  contact us at {contactEmail}.
                </p>
              </div>
              {/* Service Detail */}
            </div>
          </div>
          {/* Page Wrap */}
        </section>
        <BottomBar2></BottomBar2>
      </React.Fragment>
  );
};

export default TermsAndConditionsDetail;
