import { useEffect, useState } from "react";
import RouterLinks from "./router/Routes";
import { PushSpinner } from "react-spinners-kit";

function App() {
  const [loading, setLoading] = useState(false);
  const [showRainingCoins, setShowRainingCoins] = useState(false);



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      startRainingCoins(); // Immediately trigger coin rain
    }, 1000);
  }, []);

  function startRainingCoins() {
    setShowRainingCoins(true);
    const rainContainer = document.createElement("div");
    rainContainer.className = "rain-container";

    // Append coins to the container
    for (let i = 0; i < 500; i++) {
      const coin = document.createElement("div");
      coin.className = "coin";
      coin.style.left = `${Math.random() * 100}vw`;
      coin.style.animationDelay = `${Math.random() * 3}s`; // Stagger animation
      coin.style.animationDuration = `${Math.random() * 3 + 3}s`; // Randomize animation duration (3-6 seconds)
      rainContainer.appendChild(coin);
    }

    document.body.appendChild(rainContainer);

    // Remove coins after animation completes
    setTimeout(() => {
      rainContainer.remove();
      setShowRainingCoins(false);
    }, 9000);
  }

  return (
      <div className="lcotApp">
        {loading ? (
            <div className="pageLoader fixed justify-center items-center inset-0 flex">
              <PushSpinner size={60} color="#284be5"></PushSpinner>
            </div>
        ) : (
            <RouterLinks></RouterLinks>
        )}
      </div>
  );
}

export default App;
