import { BrowserRouter, Routes, Route } from "react-router-dom";
import usePageTracking from '../hooks/usePageTracking';
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import { BlogDetail, BlogPage, BlogPage2 } from "../pages/BlogPages";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage, HomePage2 } from "../pages/HomePages";
import { PlansPage } from "../pages/PlansPage";
import { PortfolioDetail, PortfolioPage, PortfolioPage2, PortfolioPage3 } from "../pages/PortfolioPages";
import { ServiceDetail, ServicePage } from "../pages/ServicePages";
import { TermsAndConditionsDetail } from "../pages/TermsAndConditions";
import {PrivacyPolicyDetail} from "../pages/PrivacyPolicy";

const RouterLinks = () => {
  return (
    <>
      <BrowserRouter>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage></HomePage>} />
            <Route path="/terms" element={<TermsAndConditionsDetail></TermsAndConditionsDetail>} />
            <Route path="/privacypolicy" element={<PrivacyPolicyDetail></PrivacyPolicyDetail>} />
            {/*<Route path="/404" element={<ErrorPage></ErrorPage>} />*/}
            <Route path="*" element={<HomePage></HomePage>} />
          </Routes>
        </SmoothScroll>
      </BrowserRouter>
    </>
  );
};

export default RouterLinks;
