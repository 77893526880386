export const education = [
  {
    eduDate: "austin@lcot.live",
    eduTitle: "Austin Fowler",
    eduIns: "Chief Executive Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "tyler@lcot.live",
    eduTitle: "Tyler Williams",
    eduIns: "Chief Operating Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    eduDate: "jaime@lcot.live",
    eduTitle: "Jaime Bernal",
    eduIns: "Chief Technology Officer",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  }
];
