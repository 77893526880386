import ExpImg1 from "../assets/images/resources/570x555.png";
import ExpImg2 from "../assets/images/resources/570x555.png";
import ExpImg3 from "../assets/images/resources/570x555.png";
import ExpImg4 from "../assets/images/resources/570x555.png";

export const workExp = [
        {
            "expCount": "Q1",
            "expDate": "January 2025 - March 2025",
            "expImg": ExpImg1,
            "expTitle": "Launch & Expansion",
            "expSubTitle": "Centralized Exchange Partnership",
            "expDesc": "We will run 7 press releases over 2 weeks for maximum visibility, followed by listing on a top 100 exchange with 1M+ daily active users. Our goal: $100M Market Cap, with a vision for $1B long-term."
        },
        {
            "expCount": "Q2",
            "expDate": "April 2025 - June 2025",
            "expImg": ExpImg2,
            "expTitle": "Community & Growth",
            "expSubTitle": "Engaging Holders & Partnerships",
            "expDesc": "Focus on building firm holder confidence, expanding partnerships, and driving adoption. Host community challenges, raffles, and AMAs to maintain engagement and momentum."
        },
        {
            "expCount": "Q3",
            "expDate": "July 2025 - September 2025",
            "expImg": ExpImg3,
            "expTitle": "Ecosystem Expansion",
            "expSubTitle": "Utility Development & Integrations",
            "expDesc": "Develop and launch new utilities for LCOT, including tipping systems, raffles, voting features, and interactive challenges. Partner with streamers and platforms to expand LCOT’s reach."
        },
        {
            "expCount": "Q4",
            "expDate": "October 2025 - December 2025",
            "expImg": ExpImg4,
            "expTitle": "Global Presence",
            "expSubTitle": "Mass Adoption Initiatives",
            "expDesc": "Execute large-scale marketing campaigns, onboard new exchanges, and target a global audience. Secure strategic partnerships to position LCOT for sustained long-term growth."
        }
    ]

;
