import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

export const social = [
  {
    socialIcon: <FontAwesomeIcon
        icon={faYoutube}
        className="h-7 w-7 md:h-10 md:w-10 text-white hover:text-accent2"
        style={{ fontSize: '4.25rem' }}
    />,
    socialLink: "https://www.youtube.com/@LCOTLive",
    socialTitle: "YouTube"
  },
  {
    socialIcon: <FontAwesomeIcon
        icon={faXTwitter}
        className="h-7 w-7 md:h-10 md:w-10 text-white hover:text-accent2"
        style={{ fontSize: '4.25rem' }}
    />,
    socialLink: "https://x.com/lcotlive",
    socialTitle: "X"
  },
  {
    socialIcon: <FontAwesomeIcon
        icon={faDiscord}
        className="h-7 w-7 md:h-10 md:w-10 text-white hover:text-accent2"
        style={{ fontSize: '4.25rem' }}
    />,
    socialLink: "https://discord.gg/UKAMK59MQB",
    socialTitle: "Discord"
  },
];
