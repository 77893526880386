import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.css";
import "./styles/coindrop.css";
import App from "./App";
import ReactGA from 'react-ga4';
import ReactGAUniversal from 'react-ga';

const lcot = ReactDOM.createRoot(document.getElementById("lcot"));

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize("G-G0WF2XF6LF");

// Initialize Google Analytics with your Tracking ID
// ReactGAUniversal.initialize("UA-XXXXXXXXX-X");

lcot.render(<App />);
