import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import PatternImg2 from "../../assets/images/patternImg2.jpg";

const MenuLinks = () => {
  return (
    <nav className="relative hidden lg:block">
      <ul className="flex flex-col lg:flex-row gap-4 lg:gap-10">
        <li className="menuItemHasChildren relative group text-[1rem] lg:text-[1.125rem] font-Poppins font-semibold uppercase">
          <a
            className="group-hover:text-accent text-accent2 pr-5 relative block"
            href="https://discord.gg/UKAMK59MQB"
            title="Join Our Discord"
          >
            Join Our Discord
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MenuLinks;
