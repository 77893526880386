import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const utmSource = urlParams.get('utm_source') || 'Direct';
        const utmMedium = urlParams.get('utm_medium') || 'Unknown';
        const utmCampaign = urlParams.get('utm_campaign') || 'None';

        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            referrer: document.referrer || 'Direct',
            utmSource,
            utmMedium,
            utmCampaign,
        });
    }, [location]);
};

export default usePageTracking;
